import React, { useEffect, useState } from 'react';
import Map from '../components/Map';
import MapMarker from '../components/Marker/MapMarker';
import { fetchData, Rental } from '../api-calls/rentals';
import { Link } from '@reach/router';

const DEFAULTS = {
  center: {
    lat: 60.1841495,
    lng: 24.821329,
  },
  zoom: 15,
};

const MapPage: React.FC = () => {
  const handleLocation = (pos: Position) => {
    setViewLocation({ lat: pos.coords.latitude, lng: pos.coords.longitude });
  };

  const geolocationError = (error: PositionError) => {};

  const geolocationQuery = () =>
    navigator.geolocation.getCurrentPosition(handleLocation, geolocationError, { enableHighAccuracy: true });

  const [ viewLocation, setViewLocation ] = useState<{ lat: number; lng: number }>(DEFAULTS.center);
  const [clickedIdx, setClicked] = useState<number | null>(null);

  const [ matches, setMatches ] = useState<Rental[]>([]);
  useEffect(() => {
    geolocationQuery();
    fetchData().then(res => setMatches(res));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
  <div>
    <div className="row">
      <Link to="/">
        <button className="button">Go back</button>
      </Link>
      <h1 className="header">Locate your match</h1>
    </div>
    <div className="mapContainer">
      <Map
        defaultCenter={DEFAULTS.center}
        defaultZoom={DEFAULTS.zoom}
        center={viewLocation}
      >
        {matches.map((match, index) => (
          <MapMarker
            key={index}
            lat={match.location.latitude}
            lng={match.location.longitude}
            match={match}
            onClick={() => {
              index === clickedIdx
              ? setClicked(null)
              : setClicked(index)
            }}
            showInfo={clickedIdx === index}
          />
        ))}
      </Map>{' '}
    </div>
  </div>
);
  };

export default MapPage;
