import React from 'react';
import GoogleMapReact, { Props } from 'google-map-react';
import environment from '../environment';

type MapProps = Omit<Props, "bootstrapURLKeys">;

const Map: React.FC<MapProps> = ({ children, ...props }) => {
  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: environment.REACT_APP_MAP_KEY,
      }}
      {...props}
    >
      {children}
    </GoogleMapReact>
  );
};

export default Map;
