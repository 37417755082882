import Axios from "axios";
import environment from "../environment";
import { Rental as BackendRental } from "../../../backend/src/rentals/domain";

export type Rental = BackendRental;

export enum PaperQuality {
  PREMIUM='PREMIUM',
  SERLA='SERLA',
  RECYCLED='RECYCLED',
  STYLED='STYLED',
  SAND_PAPER='SAND_PAPER'
}

export const fetchData = async (): Promise<Rental[]> => {
  if (environment.REACT_APP_BACKEND_URL) {
    try {
      const url = `${environment.REACT_APP_BACKEND_URL}/rentals`;
      const result = await Axios.get(url);
      return result.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
  throw new Error("No required runtime environment variables present!");
};

export const postData = async (rental: Rental): Promise<Rental> => {
  if (environment.REACT_APP_BACKEND_URL) {
    try {
      const url = `${environment.REACT_APP_BACKEND_URL}/rentals`;
      const result = await Axios.post(url, {rental: rental});
      return result.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
  throw new Error("No required runtime environment variables present!");
}
