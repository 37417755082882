import React, { HTMLAttributes } from "react";
import locateImg from "../assets/location.svg";
import "./LocateMe.css";

type LocateMeProps = Omit<HTMLAttributes<HTMLImageElement> , "src"|"alt"|"className"> & {

}

const LocateMe: React.FC<LocateMeProps> = (props) => (
  <>
    <img className="locate-me" src={locateImg} alt="Locate" {...props} />
    Locate yourself!
  </>
);

export default LocateMe;