import React from "react";
import Marker, { MarkerProps } from "./Marker";
import "./FormMarker.css";

type FormMarkerProps = MarkerProps & {
  onClick?: () => void;
  lat: number;
  lng: number;
}

const FormMarker: React.FC<FormMarkerProps> = ({ onClick }) => (
  <div className="FormMarker" onClick={onClick}>
    <Marker />
  </div>
);

export default FormMarker;