import React from "react";
import Marker, { MarkerProps } from "./Marker";
import "./MapMarker.css";
import { Rental } from "../../api-calls/rentals";

type MapMarkerProps = MarkerProps & {
  lat: number;
  lng: number;
  match: Rental;
  showInfo?: boolean;
  onClick?: () => void;
}

const MapMarker: React.FC<MapMarkerProps> = ({ match, showInfo, onClick }) => (
  <>
  <div className="MapMarker row" onClick={onClick}>
    <Marker />
  </div>
    {showInfo && (
      <div className="Marker-desc">
        <div className="name">
          <strong>{match.nickname}</strong>
        </div>
        <div className="desc-row">
          <span id="first"><strong>Quality:</strong></span>
          <span>{match.quality}</span>
        </div>
        <div>
          <strong>Details:</strong>
        </div>
        <div className="details">
          {match.extraDetails}
        </div>
      </div>
    )}
  </>
);

export default MapMarker;