import * as React from "react";
import "./NotFoundPage.css";

export interface NotFoundPageProps { }

const NotFoundPage: React.FC<NotFoundPageProps> = () => (
  <div className="not-found-page">
    <strong>404</strong> | Ei vaan löydy
  </div>
);

export default NotFoundPage;
