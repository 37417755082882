import React from 'react';
import { Link } from '@reach/router';
import TruckEmoji from "../components/TruckEmoji";
import TPEmoji from "../components/TPEmoji";

const FrontPage = () => (
  <div className="FrontPage">
    <div className="hero animated fadeIn slow" />
    <h1 className="header animated zoomInUp slow">
      toiletpaper.rent <TruckEmoji /> <TPEmoji />
    </h1>
    <div className="container">
      <div className="row" id="first-row">
        <div className="column">
          <p className="animated fadeInLeft delay-2s">
            <strong>Toiletpaper.Rent</strong> lends a helping hand to those in need. It is impossible to forecast when
            mankind faces an exceptional situation. Then it is important to take care of our basic needs that cannot be
            dismissed.
          </p>
          <p className="animated fadeInRight delay-3s">
            Toilet paper is one of those daily products that we don’t appreciate enough before they are compromised.
            When there’s no toilet paper in stores, an another human can come to help. Because in times of need we
            always find a way to survive together. We are all different and unique.
          </p>
          <p className="animated fadeInLeft delay-4s">
            Through <strong>Toiletpaper.Rent</strong> you can find yourself a custom toilet paper seller just for your
            personal needs. In turn, when you have been lucky on the store self, you can provide your paper agilely on
            our platform. Be part of change.
          </p>
        </div>
        <div className="column">
          <iframe
            title="Our mission"
            className="animated fadeInRight delay-5s"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/S0FCG4UYa6g"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            allowFullScreen
          />
        </div>
      </div>
      <div className="row">
        <div className="column">
          <Link className="button big-button animated fadeInUp delay-5s" to="/form">
            I have toilet paper
          </Link>

          <Link className="button big-button animated fadeInUp delay-5s" to="/map">
            I don't have toilet paper
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="column animated fadeInUp delay-5s">
          <h2>Testimonials</h2>
          <div className="testimonial-header">
            <h3>Minna, 24</h3>
            <img id="minna-picture" src="/minna.jpg" alt="A young woman" />
          </div>
          <p>
            I have always been very choosy about my purchases. For me, environment and comfort are important values.
            That’s why it was big challenge for me when the products I usually buy were not available anymore.
            Fortunately from <strong>Toiletpaper.Rent</strong> I found just the right seller for me. Now I don’t have to
            run from store to another looking for The One.
          </p>
        </div>
      </div>
      <div className="footer">Copyright &copy; Raju Kakka GmbH</div>
    </div>
  </div>
);

export default FrontPage;
