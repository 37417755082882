import React from "react";
import "./Marker.css";
import toiletpaper from "../../assets/toiletpaper.svg";
import downTriangle from "../../assets/triangle.svg";

export interface MarkerProps {

}

const Marker: React.FC<MarkerProps> = () => (
  <div className="icon">
    <img src={toiletpaper} alt="Marker-icon" />
    <img className="triangle" src={downTriangle} alt="Marker-icon" />
  </div>
);

export default Marker;
