import React from "react";
import "./JaynaPage.css";
import TruckEmoji from "../components/TruckEmoji";
import TPEmoji from "../components/TPEmoji";
import { Link } from "@reach/router";

export interface JaynaPageProps { }

const JaynaPage: React.FC<JaynaPageProps> = () => (
  <div className="JaynaPage">
    <h1 className="header animated zoomInUp slow">
      Thx for contributing! />
    </h1>
    <h2>
      Share toiletpaper.rent <TruckEmoji /> <TPEmoji /> to your friends!
    </h2>
    <Link to="/">
      <button className="button button-big">Return to front page</button>
    </Link>
  </div>
);

export default JaynaPage;
