import React, { useState, useEffect } from "react";
import { Form, Text, TextArea, Select, Option, useFormApi } from "informed";

import "./FormPage.css";

import Map from "../components/Map";
import FormMarker from "../components/Marker/FormMarker";
import LocateMe from "../components/LocateMe";
import { postData, Rental, PaperQuality } from "../api-calls/rentals";
import { navigate, Link } from "@reach/router";

enum FormState {
  INITIAL,
  LOADING,
  SUCCESS,
  FAILURE
}

// Paskalampi
const DEFAULT_COORDS = {
  lat: 60.2869288,
  lng: 24.5403039,
}

type Coords = {
  lat: number;
  lng: number;
}

const submit = async (values: any) => {
  const data: Rental = {
    nickname: values.name,
    location: {
      latitude: values.lat,
      longitude: values.lng
    },
    quality: values.quality,
    extraDetails: values.details
  };

  try {
    await postData(data);
    navigate("/success")
  } catch (err) {
    console.error(err);
    window.alert("Try again")
  }
}

const HiddenInputs: React.FC<Coords> = ({ lat, lng }) => {
  const formApi = useFormApi();
  formApi.setValue("lat", lat);
  formApi.setValue("lng", lng);
  return (
    <>
      <Text field="lat" hidden />
      <Text field="lng" hidden />
    </>
  )
}


const FormPage: React.FC = () => {
  const [formState, setFormState] = useState(FormState.INITIAL);
  const [location, setLocation] = useState<Coords>(DEFAULT_COORDS);

  const handleLocation = async (position: Position) => {
    try {
        const { latitude, longitude } = position.coords;
        setLocation({ lat: latitude, lng: longitude });
        setFormState(FormState.SUCCESS);
    } catch (error) {
        geolocationError(error);
    }
  }

  const geolocationError = (error: PositionError) => {
    switch (error.code) {
        // The acquisition of the geolocation information failed because the page didn't have the permission to do it.
        case error.PERMISSION_DENIED:
            console.error("Varmista, että sallit paikantamisen");
            setFormState(FormState.FAILURE);
            break;
        // The acquisition of the geolocation failed because one or several internal sources of position returned an internal error.
        case error.POSITION_UNAVAILABLE:
            console.error("Yritä paikantamista uudelleen");
            setFormState(FormState.FAILURE);
            break;
        // The time allowed to acquire the geolocation, defined by PositionOptions.timeout information that was reached before the information was obtained.
        case error.TIMEOUT:
            console.error("Yritä paikantamista uudelleen");
            setFormState(FormState.FAILURE);
            break;
        default:
            console.error("Yritä paikantamista uudelleen");
            setFormState(FormState.FAILURE);
            break;
    }
  };

  const geolocationQuery = () => navigator.geolocation.getCurrentPosition(handleLocation, geolocationError, { enableHighAccuracy: true });

  const [mapDraggable, setMapDraggable] = useState(true);

  const onMarkerMove = (key: any, marker: any, newCoords: any) => {
    setLocation(newCoords);
  }

  useEffect(() => {
    geolocationQuery()
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="FormPage">
      <div className="row">
        <Link to="/">
          <button className="button">Go back</button>
        </Link>
        <h1 className="header">Join the community and help the people in need!</h1>
      </div>
      <div className="container">
        <div className="row">
          <Form className="form" onSubmit={submit}
          >
            <div className="locate row">
              <LocateMe onClick={() => geolocationQuery()} />
            </div>
            <HiddenInputs lat={location.lat} lng={location.lng} />
            <label>Nickname:</label>
            <Text type="text" field="name" placeholder="Erving the Engineer" required />


            <label>Paper quality:</label>
            <Select field="quality" required>
              <Option value="" disabled>
                Select One...
              </Option>
              {Object.keys(PaperQuality).map((key => (
                <Option value={key}>
                  {key}
                </Option>
              )))}

            </Select>

            <label>Extra details:</label>
            <TextArea type="textarea" field="details" placeholder="How a person in need can contact you?" />

            <button className="button big-button" type="submit">Submit</button>
          </Form>

          {formState !== FormState.INITIAL && (
            <div className="form-map-container">
              <h5>Set your location by dragging the toilet paper</h5>
              <Map
                draggable={mapDraggable}
                defaultCenter={DEFAULT_COORDS}
                center={location}
                zoom={14}
                onChildMouseDown={() => {setMapDraggable(false)}}
                onChildMouseUp={() => {setMapDraggable(true)}}
                onChildMouseMove={onMarkerMove}
              >
                <FormMarker
                  lat={location.lat}
                  lng={location.lng}
                />
              </Map>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default FormPage;
