import React from 'react';
import { Router, RouteComponentProps } from '@reach/router';
import FrontPage from './pages/FrontPage';
import MapPage from './pages/MapPage';
import FormPage from "./pages/FormPage";
import JaynaPage from "./pages/JaynaPage";
import './App.css';
import NotFoundPage from './pages/NotFoundPage';

const FrontPageRoute = (props: RouteComponentProps) => <FrontPage />;
const MapPageRoute = (props: RouteComponentProps) => <MapPage />;
const FormPageRoute = (props: RouteComponentProps) => <FormPage />;
const JaynaPageRoute = (props: RouteComponentProps) => <JaynaPage />
const NoMatch = (props: RouteComponentProps) => <NotFoundPage />

const AppRouter = () => (
  <Router className="App">
    <FrontPageRoute path="/" />
    <MapPageRoute path="map" />
    <FormPageRoute path="form" />
    <JaynaPageRoute path="success" />
    <NoMatch default />
  </Router>
);

export default AppRouter;
